import PropTypes from 'prop-types'

import NotificationIcon from '@thebeansgroup/ui_icons/notification_icon'

import * as styles from './styles'

const HeaderProfileMenuItem = ({
  label,
  href,
  onClick,
  hasNotification,
  id
}) => {
  const renderNotificationIcon = () => {
    if (!hasNotification) return null

    return (
      <div
        role='presentation'
        css={styles.notificationIcon}
      >
        <NotificationIcon />
      </div>
    )
  }

  return (
    <li>
      <a
        css={styles.link}
        href={href}
        onClick={onClick}
        data-testid={id}
      >
        <span css={styles.text}>
          {renderNotificationIcon()}
          {label}
        </span>
      </a>
    </li>
  )
}

/* istanbul ignore next */
HeaderProfileMenuItem.defaultProps = {
  hasNotification: false,
  id: '',
  onClick: () => {},
  href: ''
}

HeaderProfileMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  hasNotification: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired
}

export default HeaderProfileMenuItem
