import PropTypes from 'prop-types'

import HeaderCCGMenuList from '@components/header_ccg_menu_list'
import { DISCOUNT_TYPE, LOGIN_TYPE, SIGNUP_TYPE } from '@constants/navigation'

import * as styles from './styles'

const HeaderCCGDropdown = ({ type }) => {
  return (
    <div css={styles.dropdownContainer}>
      <HeaderCCGMenuList type={type} />
    </div>
  )
}

HeaderCCGDropdown.propTypes = {
  type: PropTypes.oneOf([SIGNUP_TYPE, LOGIN_TYPE, DISCOUNT_TYPE]).isRequired
}

export default HeaderCCGDropdown
