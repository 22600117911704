import PropTypes from 'prop-types'

import * as styles from './styles'

const Container = ({ children, fullWidth, padding, id }) => (
  <div
    id={id}
    css={styles.container(fullWidth, padding)}
  >
    {children}
  </div>
)

Container.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  padding: PropTypes.bool
}

Container.defaultProps = {
  fullWidth: false,
  padding: true,
  id: null
}

export default Container
