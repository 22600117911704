import { gql } from '@apollo/client'

import { PROFILE_SECTION_VIEWER_FRAGMENT } from '@components/header_profile_section/constants'

export const HEADER_NAVIGATION_DRAWER_VIEWER_FRAGMENT = gql`
  fragment HeaderNavigationDrawerViewer on AccountsViewer {
    isLoggedIn
    ...ProfileSectionViewer
  }
  ${PROFILE_SECTION_VIEWER_FRAGMENT}
`

export const CLOSE_ICON_HEIGHT = '24'

export const CLOSE_ICON_WIDTH = '24'
