import { DISCOUNT_TYPE, SIGNUP_TYPE } from '@constants/navigation'
import { getLoginUrl, getSignupUrl } from '@helpers/auth'
import { getNextRoute } from '@helpers/routes'

export const getButtonHref = (country, id, type, router) => {
  if (type === SIGNUP_TYPE) return getSignupUrl(country, router, id)

  if (type === DISCOUNT_TYPE)
    return getNextRoute('discounts.ccg', { ccg: id, country })

  return getLoginUrl(country, router, id)
}
