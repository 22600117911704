export const COUNTRIES = {
  uk: 'United Kingdom',
  us: 'United States',
  ie: 'Ireland',
  ca: 'Canada',
  au: 'Australia'
}

export const ICON_WIDTH = '16px'

export const FLAG_WIDTH = 24

export const FLAG_HEIGHT = 18
