import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const link = css`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 4px;
  border-radius: 3px;

  svg g g {
    fill: ${Colours('white', 300)};
  }

  &:hover {
    svg g g {
      fill: ${Colours('grey', 200)};
    }
  }
`

export const name = css`
  color: ${Colours('huckleberry', '500')};
  padding-right: 16px;
  font-weight: 600;
`

export const chevronIcon = css`
  margin-left: 4px;
  line-height: 0;
`
