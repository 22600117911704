import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const headerMenuItems = css`
  display: block;
  background-color: ${Colours('white')};
  padding: 8px 0;
  list-style-type: none;
`

export const link = css`
  display: block;
  padding: 8px 16px;
  color: ${Colours('grey', 500)};

  :hover {
    color: ${Colours('grey', 500)};
    background-color: ${Colours('liquorice', 100)};
  }
`

export const text = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`
