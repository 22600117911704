import PropTypes from 'prop-types'
import { useState } from 'react'

import MenuIcon from '@thebeansgroup/ui_icons/menu_icon'

import Container from '@components/container'
import CountryLink from '@components/country_link'
import HeaderDiscountsButton from '@components/header_discounts_button'
import HeaderLogo from '@components/header_logo'
import HeaderNavLinks from '@components/header_nav_links'
import HeaderNavigationDrawer from '@components/header_navigation_drawer'
import HeaderTopUserSection from '@components/header_top_user_section'
import { HEADER_NAVBAR_TYPE } from '@constants/navigation'
import { useCountryCode } from '@hooks/use_country_code'

import * as styles from './styles'

const Header = ({ transparent }) => {
  const [isOpen, setIsOpen] = useState(false)
  const country = useCountryCode()

  const openNavDrawer = () => setIsOpen(true)
  const closeNavDrawer = () => setIsOpen(false)

  const renderMenuButton = () => (
    <button
      css={styles.menuButton}
      onClick={openNavDrawer}
      data-testid='menu-button'
    >
      <MenuIcon
        colour='white'
        width='25'
        height='25'
      />
    </button>
  )

  const renderNavDrawer = () => (
    <HeaderNavigationDrawer
      isOpen={isOpen}
      closeNavDrawer={closeNavDrawer}
    />
  )

  const renderCountryLink = () => (
    <div css={styles.countryLink}>
      <CountryLink
        country={country}
        position='header'
      />
    </div>
  )

  return (
    <header
      role='banner'
      css={styles.header(transparent)}
    >
      <Container full>
        <div css={styles.headerContainer}>
          <div css={styles.headerTop}>
            {renderMenuButton()}
            <HeaderLogo />
            {renderCountryLink()}
            <HeaderDiscountsButton variant={HEADER_NAVBAR_TYPE} />
            <HeaderNavLinks />
            {renderNavDrawer()}
          </div>
          <HeaderTopUserSection />
        </div>
      </Container>
    </header>
  )
}

Header.propTypes = {
  transparent: PropTypes.bool
}

Header.defaultProps = {
  transparent: false
}

export default Header
