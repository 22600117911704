import PropTypes from 'prop-types'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'

import * as styles from './styles'

const Flag = ({ country, width, height }) => {
  if (!country) return null

  return (
    <div
      data-testid='icon'
      css={styles.flag}
    >
      <SafeImage
        alt=''
        src={withAssetPrefix(`images/flags/${country.toLowerCase()}.svg`)}
        width={width}
        height={height}
      />
    </div>
  )
}

Flag.propTypes = {
  country: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

Flag.defaultProps = {
  country: '',
  width: 30,
  height: 22
}

export default Flag
