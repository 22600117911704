import PropTypes from 'prop-types'

import Placeholder from '@components/placeholder'

const ProfileImagePlaceholder = ({ width, height }) => (
  <Placeholder
    height={height}
    width={width}
    circular
  />
)

ProfileImagePlaceholder.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
}

export default ProfileImagePlaceholder
