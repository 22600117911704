import { gql } from '@apollo/client'

export const HEADER_TOP_USER_SECTION_VIEWER_FRAGMENT = gql`
  fragment HeaderTopUserSectionViewer on AccountsViewer {
    id
    isLoggedIn
    userProfile {
      id
      firstName
      lastName
      verified
    }
  }
`
