import PropTypes from 'prop-types'

import * as styles from './styles'

const PlaceholderAnimation = ({ left, width }) => {
  return (
    <div
      role='presentation'
      aria-hidden='true'
      css={styles.animationOverlay(left, width)}
    />
  )
}

PlaceholderAnimation.propTypes = {
  left: PropTypes.string,
  width: PropTypes.string
}

PlaceholderAnimation.defaultProps = {
  left: '-60%',
  width: '60%'
}

export default PlaceholderAnimation
