import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${Colours('grey', '500')};
  z-index: 9;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.1, 0.29, 0.17, 0.73);
`

export const overlayOpen = css`
  opacity: 0.5;
`

export const drawer = css`
  position: fixed;
  top: -100%;
  height: 100%;
  background-color: ${Colours('white')};
  width: 100%;
  max-width: 100%;
  left: 0;
  transition: transform 300ms;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const drawerOpen = css`
  transform: translateY(100%);
`

export const closeButton = css`
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 6px;
  display: block;
  padding: 24px;
  background: none;
  border: none;
  outline: none;
`

export const buttonGroup = css`
  margin-top: 6.25em;
  padding: 0 1.5em;
`

export const divider = css`
  border-bottom: 1px solid ${Colours('white')};
  margin-bottom: 1em;
  width: 100%;
`

export const logoContainer = css`
  margin: 24px 20px 20px;
  position: absolute;
  left: 74px;
  top: 0;
  height: 18px;
`

export const loginContainer = css`
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
`

export const userSectionContainer = css`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const countryLinkContainer = css`
  margin: 90px 0 10px 25px;
`

export const discountsButtonContainer = css`
  margin-left: 25px;
`
