import { css } from '@emotion/react'

export const container = (height) => css`
  position: relative;
  height: ${height};
`

export const image = css`
  border-radius: 50%;
`

export const imageContainer = css`
  position: absolute;
  top: 0px;
  right: 0px;
`
