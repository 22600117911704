import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const modalStylesDesktop = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '999',
    overflow: 'auto'
  },
  content: {
    top: '80px',
    left: '60%',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    opacity: '1',
    maxWidth: '380px',
    width: '100%',
    backgroundColor: Colours('white', 300),
    zIndex: '999',
    border: 'none'
  }
}

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '999',
    overflow: 'auto'
  },
  content: {
    backgroundColor: Colours('white', 300),
    top: 0,
    left: 0,
    right: 0,
    bottom: 'auto',
    inset: 'auto',
    opacity: '1',
    padding: '0 0 48px 0',
    width: '100%',
    height: '100%',
    zIndex: '999'
  }
}

export const closeButton = css`
  position: absolute;
  left: 24px;
  top: 24px;
`

export const logo = css`
  position: absolute;
  left: 74px;
  top: 16px;
  height: 18px;
`

export const content = css`
  margin-top: 80px;
  margin-left: 24px;
`
