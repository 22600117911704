import { css } from '@emotion/react'

import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const content = css`
  text-align: center;
  padding-top: 50px;

  ${desktop} {
    padding-top: 150px;
  }
`

export const body = css`
  margin: 20px 0 30px;
`

export const button = css`
  display: inline-block;
  width: 250px;

  ${desktop} {
    width: 450px;
  }
`
