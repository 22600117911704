import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const placeholder = (width, height, circular) => css`
  position: relative;
  height: ${height};
  width: ${width};
  background: ${Colours('grey', '100')};
  ${circular && 'border-radius: 50%;'};
`

export const rounded = css`
  border-radius: 5px;
`
