import { css } from '@emotion/react'

import { mother } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const container = css`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
`

export const discountsButton = css`
  font-weight: 600;
`

export const headerDiscountsButton = css`
  display: none;

  ${mother} {
    display: block;
    margin-top: 2px;
  }
`

export const chevronIcon = css`
  display: none;

  ${mother} {
    display: inline-block;
    margin-left: 4px;
  }
`
