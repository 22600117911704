import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const menuContainer = css`
  display: inline-block;
  position: relative;
  align-items: center;
  height: 30px;
`
export const menuButton = css`
  background: none;
  border: none;
  color: ${Colours('white')};

  span + svg g g {
    fill: ${Colours('white')};
  }

  &:hover {
    color: ${Colours('grey', 50)};
  }

  &:hover span + svg g g {
    fill: ${Colours('grey', 100)};
  }

  &:active {
    color: ${Colours('white')};
  }

  &:active span + svg g g {
    fill: ${Colours('white')};
  }
`

export const menuButtonOpen = css`
  color: ${Colours('grey', 200)};

  span + svg g g {
    fill: ${Colours('grey', 200)};
  }
`

export const menuItems = css`
  display: block;
  position: absolute;
  background-color: ${Colours('white')};
  z-index: 4;
  width: 176px;
  right: 0;
  padding: 8px 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
`
export const menuDisplayName = css`
  margin-right: 4px;
  display: inline;
`
export const menuProfilePicture = css`
  display: inline-block;
  padding: 0 8px;
  vertical-align: sub;

  svg g {
    fill: ${Colours('white')};
  }
`
