import PropTypes from 'prop-types'

import PlaceholderAnimation from '@components/placeholder_animation'

import * as styles from './styles'

const Placeholder = ({ width, height, circular, rounded }) => {
  return (
    <div
      role='presentation'
      aria-hidden='true'
      css={[
        styles.placeholder(width, height, circular),
        rounded && styles.rounded
      ]}
    >
      <PlaceholderAnimation />
    </div>
  )
}

Placeholder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  circular: PropTypes.bool,
  rounded: PropTypes.bool
}

Placeholder.defaultProps = {
  width: '100%',
  circular: false,
  rounded: false
}

export default Placeholder
