export const ROUTES = {
  apps: '/:country/about/apps',
  home: '/:country',
  brand: '/:country/:brand',
  'brand.ccg': '/:country/:brand/:ccg',
  'sbid.support': 'https\\://help.studentbeans.com/hc/:country',
  'country.links': '/:country/country-links',
  'discounts.ccg': '/:country/discounts/:ccg',
  'discounts.ccg.category': '/:country/discounts/:ccg/:category'
}

export const FOR_BUSINESS_ROUTE =
  'https://partner.studentbeans.com/brand-solutions/beans-id/'
