import { css } from '@emotion/react'

import { mother } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

import { COLOURS } from './constants'

export const button = css`
  border-radius: 150px;
  border: 0;
  font-weight: 600;
  padding: 8px 15px;
  white-space: nowrap;
  transition-property: color background-color border-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;

  ${mother} {
    padding: 10px 20px;
  }
`

export const colourStyles = (colour) => css`
  background-color: ${COLOURS[colour].base};
  border-color: ${COLOURS[colour].base};
  color: ${COLOURS[colour].base};

  &:hover {
    background-color: ${COLOURS[colour].hover};
    border-color: ${COLOURS[colour].hover};
    color: ${COLOURS[colour].hover};
  }
`

export const contained = (colour) => css`
  border-width: 0px;
  color: ${COLOURS[colour].contrast};

  &:hover {
    color: ${COLOURS[colour].contrast};
  }
`

export const outlined = css`
  background-color: transparent;
  border-width: 2px;
  border-style: solid;

  &:hover {
    background-color: transparent;
  }
`

export const text = (color) => css`
  background-color: transparent;
  border: none;
  border-radius: 0;
  line-height: 1.1;
  padding: 0 3px;
  font-weight: 400;

  &:hover {
    background-color: transparent;
  }
`
