import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const header = css`
  background-color: ${Colours('grey', '100')};
  color: ${Colours('grey', '500')};
  padding: 1.375em 0;
  text-align: center;
  min-height: 100px;
  width: 100%;
`

export const profileImageContainer = (imgSize) => css`
  width: ${imgSize};
  height: ${imgSize};
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`

export const name = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 15em;
  margin: 16px auto 0;
`

export const linksContainer = css`
  display: flex;
  flex-direction: row;
`

export const link = css`
  display: flex;
  align-items: center;
  padding: 0.75em 1.5em;
  color: ${Colours('grey', '500')};

  div svg,
  svg {
    display: block;
    margin-right: 1em;
  }
`

export const userSectionContainer = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
