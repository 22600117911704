import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import {
  desktop,
  mother
} from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const header = (transparent) => css`
  position: relative;
  z-index: 4;
  background-color: ${transparent
    ? 'transparent'
    : Colours('huckleberry', '500')};
  max-width: 100%;
  padding: 0 5px;

  ${desktop} {
    padding: 0 20px;
  }
`

export const menuButton = css`
  justify-self: flex-start;
  background: none;
  border: 0;
  outline: none;
  padding: 10px 15px 10px 0;

  ${mother} {
    display: none;
  }

  svg {
    margin-top: 8px;
  }
`

export const headerContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
`

export const headerTop = css`
  display: flex;
  justify-content: flex-start;
`

export const navList = css`
  display: flex;
  align-items: center;
`

export const primaryLinks = css`
  justify-self: center;

  ${mother} {
    justify-self: flex-start;
    display: flex;
    margin-right: 18px;
  }
`

export const countryLink = css`
  display: none;
  align-self: center;
  margin: 0 20px 0 40px;

  ${mother} {
    display: flex;
  }
`
