export const BACK_TO_HOME = 'Back to home'

export const FOUR_OH_FOUR_TEXT =
  'Head back to the home page to explore all the biggest discounts we have on offer right now.'
export const FOUR_OH_FOUR_TITLE = "Uh oh, we can't find that page"

export const FIVE_HUNDRED_TEXT =
  "We're experiencing a few technical issues. Please try again later."
export const FIVE_HUNDRED_TITLE = 'Something went wrong'

export const FIVE_OH_THREE_TEXT = 'Please wait a moment and try again.'
export const FIVE_OH_THREE_TITLE = "Oops, we're over capacity"

export const TEXT_CONTENT = {
  fiveohthree: {
    text: FIVE_OH_THREE_TEXT,
    title: FIVE_OH_THREE_TITLE
  },
  fivehundred: {
    text: FIVE_HUNDRED_TEXT,
    title: FIVE_HUNDRED_TITLE
  },
  fourohfour: {
    text: FOUR_OH_FOUR_TEXT,
    title: FOUR_OH_FOUR_TITLE
  }
}
