import PropTypes from 'prop-types'

import LoadingSpinner from '@thebeansgroup/ui_loaders/loading_spinner'

import { COLOUR_NAMES } from './constants'
import { getCSS } from './helpers'
import * as styles from './styles'

const BeansIdButton = ({
  component: Component,
  children,
  onClick,
  css,
  colour,
  variant,
  loading,
  ...props
}) => {
  const getContent = () => {
    if (loading) {
      return (
        <LoadingSpinner
          width='16px'
          height='16px'
          label='Loading...'
        />
      )
    }
    return children
  }
  return (
    <Component
      css={[styles.button, ...getCSS(css, colour, variant)]}
      onClick={onClick}
      {...props}
    >
      {getContent()}
    </Component>
  )
}

BeansIdButton.propTypes = {
  component: PropTypes.oneOf(['button', 'a']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClick: PropTypes.func,
  css: PropTypes.string,
  colour: PropTypes.oneOf(COLOUR_NAMES),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  loading: PropTypes.bool
}

/* istanbul ignore next */
BeansIdButton.defaultProps = {
  component: 'button',
  children: null,
  css: '',
  colour: 'white',
  variant: 'contained',
  onClick: () => {},
  loading: false
}

export default BeansIdButton
