export const LOCALES = {
  AUSTRALIA: 'au',
  AUSTRIA: 'at',
  BELGIUM: 'be',
  CANADA: 'ca',
  CHINA: 'cn',
  DENMARK: 'dk',
  FRANCE: 'fr',
  GERMANY: 'de',
  IRELAND: 'ie',
  ITALY: 'it',
  NETHERLANDS: 'nl',
  NEW_ZEALAND: 'nz',
  POLAND: 'pl',
  SPAIN: 'es',
  SWEDEN: 'se',
  UNITED_KINGDOM: 'uk',
  UNITED_STATES: 'us'
}

export const COUNTRY_CODE_TO_LOCALE_MAP = {
  [LOCALES.AUSTRALIA]: 'en',
  [LOCALES.AUSTRIA]: 'de',
  [LOCALES.BELGIUM]: 'nl-BE',
  [LOCALES.CANADA]: 'en',
  [LOCALES.CHINA]: 'zh-Hans',
  [LOCALES.DENMARK]: 'da',
  [LOCALES.FRANCE]: 'fr',
  [LOCALES.GERMANY]: 'de',
  [LOCALES.IRELAND]: 'en',
  [LOCALES.ITALY]: 'it',
  [LOCALES.NETHERLANDS]: 'nl',
  [LOCALES.NEW_ZEALAND]: 'en',
  [LOCALES.POLAND]: 'pl',
  [LOCALES.SPAIN]: 'es',
  [LOCALES.SWEDEN]: 'sv',
  [LOCALES.UNITED_KINGDOM]: 'en-GB',
  [LOCALES.UNITED_STATES]: 'en_US'
}

export const COUNTRY_CODE_TO_HTML_LOCALE_MAP = {
  [LOCALES.AUSTRALIA]: 'en-au',
  [LOCALES.AUSTRIA]: 'de-at',
  [LOCALES.BELGIUM]: 'nl-be',
  [LOCALES.CANADA]: 'en-ca',
  [LOCALES.CHINA]: 'zh-cn',
  [LOCALES.DENMARK]: 'da-dk',
  [LOCALES.FRANCE]: 'fr-fr',
  [LOCALES.GERMANY]: 'de-de',
  [LOCALES.IRELAND]: 'en-ie',
  [LOCALES.ITALY]: 'it-it',
  [LOCALES.NETHERLANDS]: 'nl-nl',
  [LOCALES.NEW_ZEALAND]: 'en-nz',
  [LOCALES.POLAND]: 'pl-pl',
  [LOCALES.SPAIN]: 'es-es',
  [LOCALES.SWEDEN]: 'sv-se',
  [LOCALES.UNITED_KINGDOM]: 'en-gb',
  [LOCALES.UNITED_STATES]: 'en-us'
}

export const COUNTRY_CODE_TO_ZENDESK_LOCALE_MAP = {
  [LOCALES.AUSTRALIA]: 'en-us',
  [LOCALES.AUSTRIA]: 'en-us',
  [LOCALES.BELGIUM]: 'nl-be',
  [LOCALES.CANADA]: 'en-us',
  [LOCALES.CHINA]: 'zh-cn',
  [LOCALES.DENMARK]: 'da',
  [LOCALES.FRANCE]: 'fr',
  [LOCALES.GERMANY]: 'de',
  [LOCALES.IRELAND]: 'en-us',
  [LOCALES.ITALY]: 'it',
  [LOCALES.NETHERLANDS]: 'nl',
  [LOCALES.NEW_ZEALAND]: 'en-us',
  [LOCALES.POLAND]: 'pl',
  [LOCALES.SPAIN]: 'es',
  [LOCALES.SWEDEN]: 'sv',
  [LOCALES.UNITED_KINGDOM]: 'en-us',
  [LOCALES.UNITED_STATES]: 'en-us'
}
