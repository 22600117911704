import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '999',
    overflow: 'auto'
  },
  content: {
    backgroundColor: Colours('white', 300),
    top: 0,
    left: 0,
    right: 0,
    bottom: 'auto',
    inset: 'auto',
    opacity: '1',
    padding: '0 0 48px 0',
    width: '100%',
    zIndex: '999'
  }
}
