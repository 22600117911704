import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const link = css`
  display: block;
  padding: 8px 16px;
  color: ${Colours('grey', 500)};

  :hover {
    color: ${Colours('grey', 500)};
    background-color: ${Colours('liquorice', 100)};
  }
`

export const text = css`
  position: relative;
  display: block;
`

export const notificationIcon = css`
  position: absolute;
  top: -5px;
  left: -5px;
`
