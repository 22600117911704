import AccountIcon from '@thebeansgroup/ui_icons/account_icon'
import MenuIcon from '@thebeansgroup/ui_icons/menu_icon'

import { getIsLoggedIn } from '@helpers/auth'

export const getIcon = (data) => {
  if (getIsLoggedIn(data)) return AccountIcon

  return MenuIcon
}
