import PropTypes from 'prop-types'
import { useState } from 'react'

import ChevronIcon from '@thebeansgroup/ui_icons/chevron_icon'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import BeansIdButton from '@components/beans_id_button'
import HeaderCCGDropdown from '@components/header_ccg_dropdown'
import HeaderCCGModal from '@components/header_ccg_modal'
import {
  DISCOUNT_TYPE,
  DRAWER_NAVBAR_TYPE,
  HEADER_NAVBAR_TYPE
} from '@constants/navigation'
import {
  getHeaderLinkColour,
  isCompactNav,
  isHeaderVariant
} from '@helpers/header'

import { DISCOUNTS_TEXT, ICON_WIDTH } from './constants'
import * as styles from './styles'

const HeaderDiscountsButton = ({ variant }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const renderCCGMenuItems = () => {
    if (!drawerOpen) return null

    if (!isCompactNav()) {
      return <HeaderCCGDropdown type={DISCOUNT_TYPE} />
    }

    return (
      <HeaderCCGModal
        isOpen={drawerOpen}
        handleModalClose={() => setDrawerOpen(false)}
        type={DISCOUNT_TYPE}
      />
    )
  }

  const renderChevronIcon = () => (
    <div css={styles.chevronIcon}>
      <ChevronIcon
        rotateDegrees={drawerOpen ? 270 : 90}
        width={ICON_WIDTH}
        colour={Colours('white', 300)}
      />
    </div>
  )

  const renderButton = () => {
    return (
      <BeansIdButton
        colour={getHeaderLinkColour(variant)}
        component={'button'}
        css={[
          styles.discountsButton,
          isHeaderVariant(variant) && styles.headerDiscountsButton
        ]}
        variant='text'
        onClick={() => setDrawerOpen(!drawerOpen)}
        data-testid='ccg-menu-items-button'
      >
        {DISCOUNTS_TEXT}
        {renderChevronIcon()}
      </BeansIdButton>
    )
  }

  return (
    <div
      css={styles.container}
      onMouseLeave={() => setDrawerOpen(false)}
    >
      {renderButton()}
      {renderCCGMenuItems()}
    </div>
  )
}

HeaderDiscountsButton.propTypes = {
  variant: PropTypes.oneOf([HEADER_NAVBAR_TYPE, DRAWER_NAVBAR_TYPE])
}

HeaderDiscountsButton.defaultProps = {
  variant: HEADER_NAVBAR_TYPE
}

export default HeaderDiscountsButton
