import PropTypes from 'prop-types'

import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'
import CloseIcon from '@thebeansgroup/ui_icons/close_icon'
import Headline from '@thebeansgroup/ui_typography/headline'

import HeaderCCGMenuList from '@components/header_ccg_menu_list'
import HeaderLogo from '@components/header_logo'
import Modal from '@components/modal'
import { LOGIN_TYPE, SIGNUP_TYPE, DISCOUNT_TYPE } from '@constants/navigation'

import { CLOSE_ICON_SIZE } from './constants'
import { getModalStyles, getTitle } from './helpers'
import * as styles from './styles'

const HeaderCCGModal = ({ isOpen, handleModalClose, type }) => {
  const renderCloseButton = () => {
    return (
      <UnstyledButton
        data-testid='close_button'
        onClick={handleModalClose}
        css={styles.closeButton}
      >
        <CloseIcon
          height={CLOSE_ICON_SIZE}
          width={CLOSE_ICON_SIZE}
        />
      </UnstyledButton>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleModalClose}
      styles={getModalStyles()}
    >
      {renderCloseButton()}
      <div css={styles.logo}>
        <HeaderLogo type='light' />
      </div>
      <div css={styles.content}>
        <Headline component='h3'>{getTitle(type)}</Headline>
        <HeaderCCGMenuList type={type} />
      </div>
    </Modal>
  )
}

HeaderCCGModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf([SIGNUP_TYPE, LOGIN_TYPE, DISCOUNT_TYPE])
}

HeaderCCGModal.defaultProps = {
  type: LOGIN_TYPE
}

export default HeaderCCGModal
