import { css } from '@emotion/react'

export const wrapper = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`

export const main = (backgroundColour) => css`
  flex: 1;
  padding-bottom: 40px;
  background-color: ${backgroundColour};
`

export const smoothScroll = css`
  html {
    scroll-behavior: smooth;
  }
`
