import CharityWorkerIcon from '@thebeansgroup/ui_icons/charity_worker_icon'
import DisabilityIcon from '@thebeansgroup/ui_icons/disability_icon'
import FirstResponderIcon from '@thebeansgroup/ui_icons/first_responder_icon'
import HealthcareIcon from '@thebeansgroup/ui_icons/healthcare_icon'
import LowIncomeIcon from '@thebeansgroup/ui_icons/low_income_icon'
import MilitaryIcon from '@thebeansgroup/ui_icons/military_icon'
import ParentIcon from '@thebeansgroup/ui_icons/parent_icon'
import TeacherIcon from '@thebeansgroup/ui_icons/teacher_icon'

export const ACTIVE_CONSUMER_GROUPS = [
  {
    id: 'healthcare',
    label: 'Healthcare',
    icon: HealthcareIcon
  },
  {
    id: 'military',
    label: 'Military',
    icon: MilitaryIcon
  },
  {
    id: 'first-responder',
    label: 'First Responders',
    icon: FirstResponderIcon
  },
  {
    id: 'teacher',
    label: 'Teachers',
    icon: TeacherIcon
  },
  {
    id: 'parent',
    label: 'Parents',
    icon: ParentIcon
  },
  {
    id: 'low-income',
    label: 'Low Income',
    icon: LowIncomeIcon
  },
  {
    id: 'charity-worker',
    label: 'Charity Workers',
    icon: CharityWorkerIcon
  },
  {
    id: 'disability',
    label: 'Disability',
    icon: DisabilityIcon
  }
]
