import PlaceholderAnimation from '@components/placeholder_animation'

import * as styles from './styles'

const HeaderTopUserSectionPlaceholder = () => {
  return (
    <div css={styles.placeHolder}>
      <PlaceholderAnimation />
    </div>
  )
}

export default HeaderTopUserSectionPlaceholder
