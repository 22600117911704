import PropTypes from 'prop-types'
import { useState } from 'react'

import BeansIdButton from '@components/beans_id_button'
import HeaderCCGDropdown from '@components/header_ccg_dropdown'
import HeaderCCGModal from '@components/header_ccg_modal'
import {
  DRAWER_NAVBAR_TYPE,
  HEADER_NAVBAR_TYPE,
  LOGIN_TYPE,
  SIGNUP_TYPE
} from '@constants/navigation'
import {
  getHeaderLinkColour,
  isCompactNav,
  isHeaderVariant
} from '@helpers/header'

import { SIGNUP_TEXT, LOGIN_TEXT } from './constants'
import { isLoginType } from './helpers'
import * as styles from './styles'

const HeaderAuthButton = ({ type, variant }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const renderCCGMenuItems = () => {
    if (!drawerOpen) return null

    if (!isCompactNav()) {
      return <HeaderCCGDropdown type={type} />
    }

    return (
      <HeaderCCGModal
        isOpen={drawerOpen}
        handleModalClose={() => setDrawerOpen(false)}
        type={type}
      />
    )
  }

  const renderButton = () => {
    if (isLoginType(type)) {
      return (
        <BeansIdButton
          colour={getHeaderLinkColour(variant)}
          component={'button'}
          css={isHeaderVariant(variant) && styles.headerLoginButton}
          variant='text'
          onClick={() => setDrawerOpen(!drawerOpen)}
          data-testid='header-login-button'
        >
          {LOGIN_TEXT}
        </BeansIdButton>
      )
    }
    return (
      <BeansIdButton
        colour='brightBlue'
        onClick={() => setDrawerOpen(!drawerOpen)}
        data-testid='header-signup-button'
      >
        {SIGNUP_TEXT}
      </BeansIdButton>
    )
  }

  return (
    <div
      css={styles.container}
      onMouseLeave={() => setDrawerOpen(false)}
    >
      {renderButton()}
      {renderCCGMenuItems()}
    </div>
  )
}

HeaderAuthButton.propTypes = {
  type: PropTypes.oneOf([SIGNUP_TYPE, LOGIN_TYPE]),
  variant: PropTypes.oneOf([HEADER_NAVBAR_TYPE, DRAWER_NAVBAR_TYPE])
}

HeaderAuthButton.defaultProps = {
  type: LOGIN_TYPE,
  variant: HEADER_NAVBAR_TYPE
}

export default HeaderAuthButton
