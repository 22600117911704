import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { modalStyles } from './styles'

const ModalWrapper = ({ children, isOpen, handleClose, styles }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={styles || modalStyles}
      overlayElement={(props, contentElement) => (
        <div
          {...props}
          data-testid='modal-overlay'
        >
          {contentElement}
        </div>
      )}
    >
      {children}
    </Modal>
  )
}

ModalWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  styles: PropTypes.object
}

ModalWrapper.defaultProps = {
  isOpen: false,
  styles: null
}

export default ModalWrapper
