import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const COLOURS = {
  white: {
    base: Colours('white'),
    hover: Colours('grey', 200),
    contrast: Colours('midnight', 500)
  },
  brightBlue: {
    base: Colours('brightBlue'),
    hover: Colours('brightBlue', 200),
    contrast: Colours('huckleberry', 500)
  },
  huckleberry: {
    base: Colours('huckleberry', 500),
    hover: Colours('huckleberry', 400),
    contrast: Colours('white')
  },
  midnight: {
    base: Colours('midnight', 500),
    hover: Colours('grey', 400),
    contrast: Colours('white')
  },
  secondaryBrightBlue: {
    base: Colours('brightBlue', 100),
    hover: Colours('brightBlue', 200),
    contrast: Colours('huckleberry', 500)
  }
}

export const COLOUR_NAMES = Object.keys(COLOURS)
