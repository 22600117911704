import { css } from '@emotion/react'

import { mother } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const headerContainer = css`
  display: none;

  ${mother} {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    list-style: none;
    padding: 0;
  }
`

export const drawerContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 0 30px;
  list-style: none;
  padding: 0;
`

export const navLink = (linkColur) => css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${linkColur};
  font-weight: 600;
  margin: 20px 0;

  ${mother} {
    margin: 0 20px;
  }
`
