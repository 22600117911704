import { compile } from 'path-to-regexp'
import querystring from 'query-string'
import root from 'window-or-global'

import { ROUTES } from '@constants/routes'

export const getNextRoute = (routeKey, params) => {
  const route = ROUTES[routeKey]
  if (!route) throw new Error(`Undefined route: '${routeKey}'`)
  return compile(route)(params)
}

export const getFullRoute = (routeKey, params) => {
  const route = ROUTES[routeKey]
  if (!route) throw new Error(`Undefined route: '${routeKey}'`)
  return `${process.env.NEXT_PUBLIC_DOMAIN}${compile(route)(params)}`
}

export const getMyAccountsUrl = (country) => {
  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/my/account?client_id=${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}&country=${country}`
}

export const getUrl = () => root.location?.href ?? ''

export const getUrlQueryObject = () => querystring.parseUrl(getUrl()).query
