import { gql } from '@apollo/client'
import { Global } from '@emotion/react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

import Container from '@components/container'
import ErrorBoundary from '@components/error_boundary'
import Header from '@components/header'
import { HEADER_VIEWER_FRAGMENT } from '@components/header/constants'
import { UserDataProvider } from '@components/user_data_provider'
import { useCountryCode } from '@hooks/use_country_code'

import * as styles from './styles'

const PageView = dynamic(() => import('@components/page_view'), { ssr: false })

const Footer = dynamic(import('@components/footer'))

const Page = ({
  backgroundColour,
  children,
  pageLabel,
  fullWidth,
  customContexts,
  smoothScroll,
  query,
  queryParams,
  transparentHeader,
  padding
}) => {
  const country = useCountryCode()

  const renderSmoothScroll = () => {
    if (!smoothScroll) return false

    return <Global styles={styles.smoothScroll} />
  }

  return (
    <Fragment>
      <UserDataProvider
        query={query}
        params={queryParams}
      >
        <div css={styles.wrapper}>
          <Header transparent={transparentHeader} />
          <main css={styles.main(backgroundColour)}>
            <ErrorBoundary country={country}>
              <Container
                fullWidth={fullWidth}
                padding={padding}
              >
                {children}
              </Container>
            </ErrorBoundary>
          </main>
          <Footer />
        </div>
        <PageView
          customContexts={customContexts}
          pageLabel={pageLabel}
        />
        {renderSmoothScroll()}
      </UserDataProvider>
    </Fragment>
  )
}

Page.propTypes = {
  backgroundColour: PropTypes.string,
  children: PropTypes.any.isRequired,
  pageLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  customContexts: PropTypes.arrayOf(PropTypes.object),
  smoothScroll: PropTypes.bool,
  query: PropTypes.any.isRequired,
  queryParams: PropTypes.object,
  padding: PropTypes.bool,
  transparentHeader: PropTypes.bool
}

Page.defaultProps = {
  backgroundColour: Colours('white'),
  fullWidth: false,
  customContexts: [],
  pageLabel: '',
  smoothScroll: false,
  queryParams: {},
  transparentHeader: false,
  padding: true
}

Page.fragments = {
  viewer: gql`
    fragment PageViewer on AccountsViewer {
      ...HeaderViewer
    }
    ${HEADER_VIEWER_FRAGMENT}
  `
}

export default Page
