import { MOTHER_BREAKPOINT } from '@constants/header'
import { HEADER_NAVBAR_TYPE } from '@constants/navigation'

export const isHeaderVariant = (variant) => variant === HEADER_NAVBAR_TYPE

export const getHeaderLinkColour = (variant) => {
  if (isHeaderVariant(variant)) return 'white'

  return 'huckleberry'
}

export const isCompactNav = () => window.innerWidth < MOTHER_BREAKPOINT
