import PropTypes from 'prop-types'

import IconWithNotification from '@thebeansgroup/ui_icons/icon_with_notification'

import { useUserData } from '@hooks/use_user_data'

import { getIcon } from './helpers'
import ProfileImagePlaceholder from './placeholder'
import * as styles from './styles'

const ProfileImage = ({ height, width }) => {
  const { data, loading } = useUserData()

  if (loading) {
    return (
      <ProfileImagePlaceholder
        width={width}
        height={height}
      />
    )
  }
  return (
    <div
      css={styles.container(height)}
      data-testid='profile-img'
    >
      <IconWithNotification
        icon={getIcon(data)}
        width={width}
        height={height}
      />
    </div>
  )
}

ProfileImage.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
}

export default ProfileImage
