import { css } from '@emotion/react'

import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const container = (fullWidth, padding) => css`
  width: 100%;
  max-width: ${fullWidth ? 'none' : '1250px'};
  min-width: 320px;
  margin: 0 auto;
  padding: ${padding ? '0 0.5em' : '0'};

  ${desktop} {
    padding: 0;
  }
`
