import { MOTHER_BREAKPOINT } from '@constants/header'
import { DISCOUNT_TYPE, SIGNUP_TYPE } from '@constants/navigation'

import { LOGIN_TITLE, SIGNUP_TITLE, DISCOUNTS_TITLE } from './constants'
import { modalStyles, modalStylesDesktop } from './styles'

export const getModalStyles = () =>
  window.innerWidth > MOTHER_BREAKPOINT ? modalStylesDesktop : modalStyles

export const getTitle = (type) => {
  if (type === SIGNUP_TYPE) return SIGNUP_TITLE

  if (type === DISCOUNT_TYPE) return DISCOUNTS_TITLE

  return LOGIN_TITLE
}
