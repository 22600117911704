import PropTypes from 'prop-types'

import { useCountryCode } from '@hooks/use_country_code'

import { getLinkColour, getNavLinks } from './helpers'
import * as styles from './styles'

const HeaderNavLinks = ({ variant, closeDrawer }) => {
  const country = useCountryCode()

  const linkOnClick = () => {
    if (variant === 'drawer') closeDrawer()
  }

  const linkColour = getLinkColour(variant)

  const renderLinks = () => {
    const navLinks = getNavLinks(country)
    return navLinks.map(({ label, href }) => (
      <li
        key={label}
        onClick={linkOnClick}
      >
        <a
          href={href}
          css={styles.navLink(linkColour)}
        >
          {label}
        </a>
      </li>
    ))
  }

  return <ul css={styles[`${variant}Container`]}>{renderLinks()}</ul>
}

HeaderNavLinks.propTypes = {
  variant: PropTypes.oneOf(['header', 'drawer']),
  closeDrawer: PropTypes.func
}

/* istanbul ignore next */
HeaderNavLinks.defaultProps = {
  variant: 'header',
  closeDrawer: () => {}
}

export default HeaderNavLinks
