import PropTypes from 'prop-types'
import { Fragment } from 'react'

import Column from '@thebeansgroup/ui_grids/column'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Body from '@thebeansgroup/ui_typography/body'
import Title1 from '@thebeansgroup/ui_typography/title1'

import BeansIdButton from '@components/beans_id_button'
import { FIVE_HUNDRED, FIVE_OH_THREE, FOUR_OH_FOUR } from '@constants/errors'
import { getNextRoute } from '@helpers/routes'

import { BACK_TO_HOME, TEXT_CONTENT } from './constants'
import * as styles from './styles'

const ErrorMessage = ({ type, country }) => {
  const renderButton = () => {
    if (type === FIVE_OH_THREE) return null

    return (
      <BeansIdButton
        component='a'
        href={getNextRoute('home', { country })}
        variant='contained'
        colour='brightBlue'
        css={styles.button}
      >
        {BACK_TO_HOME}
      </BeansIdButton>
    )
  }

  return (
    <Fragment>
      <Column
        mother={6}
        brother={6}
        sister={9}
        baby={12}
        center
      >
        <div css={styles.content}>
          <Title1
            component='h1'
            colour={Colours('huckleberry', 500)}
          >
            {TEXT_CONTENT[type].title}
          </Title1>
          <div css={styles.body}>
            <Body colour={Colours('liquorice', 400)}>
              {TEXT_CONTENT[type].text}
            </Body>
          </div>
          {renderButton()}
        </div>
      </Column>
    </Fragment>
  )
}

ErrorMessage.propTypes = {
  type: PropTypes.oneOf([FIVE_OH_THREE, FIVE_HUNDRED, FOUR_OH_FOUR]).isRequired,
  country: PropTypes.string.isRequired
}

export default ErrorMessage
