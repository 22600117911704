import { Fragment } from 'react'

import HeaderAuthButton from '@components/header_auth_button'
import HeaderProfileMenu from '@components/header_profile_menu'
import HeaderTopUserSectionPlaceholder from '@components/header_top_user_section_placeholder'
import { LOGIN_TYPE, SIGNUP_TYPE } from '@constants/navigation'
import { getIsLoggedIn } from '@helpers/auth'
import { useUserData } from '@hooks/use_user_data'

import * as styles from './styles'

const HeaderTopUserSection = () => {
  const { data, loading } = useUserData()
  const isLoggedIn = getIsLoggedIn(data)
  const userProfile = data?.accountsViewer?.userProfile

  if (loading) return <HeaderTopUserSectionPlaceholder />

  const renderLoginLinks = () => (
    <Fragment>
      <HeaderAuthButton type={LOGIN_TYPE} />
      <HeaderAuthButton type={SIGNUP_TYPE} />
    </Fragment>
  )

  const renderUserSection = () => {
    if (!isLoggedIn) return renderLoginLinks()

    return (
      <HeaderProfileMenu
        firstName={userProfile?.firstName}
        isLoggedIn={isLoggedIn}
        lastName={userProfile?.lastName}
      />
    )
  }
  return <div css={styles.headerAuth}>{renderUserSection()}</div>
}

export default HeaderTopUserSection
