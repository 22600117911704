import PropTypes from 'prop-types'
import { useState } from 'react'

import ChevronIcon from '@thebeansgroup/ui_icons/chevron_icon'

import HeaderProfileMenuItem from '@components/header_profile_menu_item'
import ProfileImage from '@components/profile_image'
import { LOGOUT_TEXT, MY_ACCOUNT_TEXT } from '@constants/navigation'
import { logout } from '@helpers/auth'
import { getMyAccountsUrl } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'

import { IMG_SIZE } from './constants'
import * as styles from './styles'

const HeaderProfileMenu = ({ firstName, lastName, isLoggedIn }) => {
  const [isOpen, setIsOpen] = useState(false)
  const country = useCountryCode()

  const renderProfileIconButton = () => {
    return (
      <button
        css={[styles.menuButton, isOpen && styles.menuButtonOpen]}
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        data-testid='nav-profile-menu'
      >
        <span
          css={styles.menuProfilePicture}
          data-cs-mask
        >
          <ProfileImage
            width={IMG_SIZE}
            height={IMG_SIZE}
          />
        </span>
        <span
          css={styles.menuDisplayName}
          data-cs-mask
        >
          {`${firstName} ${lastName}`}
        </span>
        <ChevronIcon rotateDegrees={isOpen ? 270 : 90} />
      </button>
    )
  }

  const renderProfileMenuItems = () => {
    if (!isOpen) return null

    return (
      <ol css={styles.menuItems}>
        <HeaderProfileMenuItem
          href={getMyAccountsUrl(country)}
          label={MY_ACCOUNT_TEXT}
          id='nav-profile-menu-account'
        />
        <HeaderProfileMenuItem
          onClick={(e) => logout(e, country)}
          label={LOGOUT_TEXT}
          id='nav-profile-menu-logout'
        />
      </ol>
    )
  }

  if (!isLoggedIn) return null

  return (
    <nav css={styles.menuContainer}>
      {renderProfileIconButton()}
      {renderProfileMenuItems()}
    </nav>
  )
}

HeaderProfileMenu.propTypes = {
  firstName: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired
}

HeaderProfileMenu.defaultProps = {
  firstName: '',
  isLoggedIn: false,
  lastName: ''
}

export default HeaderProfileMenu
