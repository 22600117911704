export const HEADER_NAVBAR_TYPE = 'header'

export const DRAWER_NAVBAR_TYPE = 'drawer'

export const LOGIN_TYPE = 'login'

export const SIGNUP_TYPE = 'signup'

export const DISCOUNT_TYPE = 'discount'

export const MY_ACCOUNT_TEXT = 'My Account'

export const LOGOUT_TEXT = 'Log Out'
