import { css } from '@emotion/react'

export const dropdownContainer = css`
  position: absolute;
  top: 50px;
  left: 50%;
  z-index: 1;
  width: 200px;
  transform: translateX(-50%);
`
