import PropTypes from 'prop-types'
import { Fragment } from 'react'

import GearIcon from '@thebeansgroup/ui_icons/gear_icon'
import LogoutIcon from '@thebeansgroup/ui_icons/logout_icon'

import ProfileImage from '@components/profile_image'
import { LOGOUT_TEXT, MY_ACCOUNT_TEXT } from '@constants/navigation'
import { logout } from '@helpers/auth'
import { getMyAccountsUrl } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'
import { useUserData } from '@hooks/use_user_data'

import { IMG_SIZE } from './constants'
import * as styles from './styles'

const HeaderProfileSection = ({ closeNavDrawer }) => {
  const { data } = useUserData()
  const country = useCountryCode()

  const logoutUser = (e) => logout(e, country)

  const renderUserDetails = (user) => (
    <div css={styles.header}>
      <div css={styles.profileImageContainer(IMG_SIZE)}>
        <ProfileImage
          width={IMG_SIZE}
          height={IMG_SIZE}
        />
      </div>
      <div css={styles.name}>
        {user?.firstName} {user?.lastName}
      </div>
    </div>
  )

  const renderUserLinks = () => (
    <div css={styles.linksContainer}>
      <a
        css={styles.link}
        href={getMyAccountsUrl(country)}
      >
        <GearIcon />
        <span>{MY_ACCOUNT_TEXT}</span>
      </a>
      <a
        css={styles.link}
        onClick={logoutUser}
      >
        <LogoutIcon />
        <span>{LOGOUT_TEXT}</span>
      </a>
    </div>
  )

  const user = data?.accountsViewer?.userProfile
  return (
    <Fragment>
      {renderUserDetails(user)}
      {renderUserLinks()}
    </Fragment>
  )
}

HeaderProfileSection.propTypes = {
  closeNavDrawer: PropTypes.func.isRequired
}

export default HeaderProfileSection
