import { captureException } from '@sentry/nextjs'
import cookies from 'js-cookie'

import { LOGIN_AUTH_PATH, SIGNUP_AUTH_PATH } from '@constants/auth'

export const getLoginUrl = (country, router, consumerGroup) => {
  const userReturnTo = getCurrentUrl(router)
  const authUrlParams = getAuthUrlParams(
    country,
    userReturnTo,
    LOGIN_AUTH_PATH,
    consumerGroup
  )
  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/oauth/authorize?${authUrlParams}`
}

export const getVerificationUrl = (country, router, ccgToVerify, expired) => {
  const userReturnTo = getCurrentUrl(router)
  const urlParams = getUrlParamStringFromObject({
    user_return_to: userReturnTo,
    consumer_group: ccgToVerify
  })

  if (expired)
    return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/verification/expired?${urlParams}`

  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/verification?${urlParams}`
}

export const getCurrentUrl = (router) => {
  const currentPath = router?.asPath
  return `${process.env.NEXT_PUBLIC_DOMAIN}${currentPath}`
}

export const getUrlParamStringFromObject = (params) => {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')
}

export const getAuthUrlParams = (
  country,
  userReturnTo,
  authPath,
  consumerGroup
) => {
  const urlObject = {
    country,
    response_type: 'code',
    client_id: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
    redirect_uri: process.env.NEXT_PUBLIC_AUTH_CALLBACK_URL,
    user_return_to: userReturnTo,
    auth_path: authPath || LOGIN_AUTH_PATH,
    consumer_group: consumerGroup
  }
  return getUrlParamStringFromObject(urlObject)
}

export const getIsLoggedIn = (data) => {
  return !!data?.accountsViewer?.isLoggedIn
}

export const getSignupUrl = (country, router, consumerGroup) => {
  const userReturnTo = getCurrentUrl(router)
  const authUrlParams = getAuthUrlParams(
    country,
    userReturnTo,
    SIGNUP_AUTH_PATH,
    consumerGroup
  )
  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/oauth/authorize?${authUrlParams}`
}

export const getLogoutUrl = (country) =>
  `${process.env.NEXT_PUBLIC_DOMAIN}/${country}/sign_out`

/* istanbul ignore next */
export const logout = async (e, country) => {
  e.preventDefault()
  cookies.remove('viewer_token', { path: '/' })

  const logOutUrl = `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/authorisation/logout`

  await fetch(logOutUrl, {
    credentials: 'include'
  })
    .catch((err) => {
      captureException(err)
    })
    .finally(() => {
      location.assign(`${process.env.NEXT_PUBLIC_DOMAIN}/${country}`)
    })
}
