import PropTypes from 'prop-types'
import { Fragment } from 'react'

import CloseIcon from '@thebeansgroup/ui_icons/close_icon'

import CountryLink from '@components/country_link'
import HeaderAuthButton from '@components/header_auth_button'
import HeaderDiscountsButton from '@components/header_discounts_button'
import HeaderLogo from '@components/header_logo'
import HeaderNavLinks from '@components/header_nav_links'
import HeaderProfileSection from '@components/header_profile_section'
import { DRAWER_NAVBAR_TYPE, SIGNUP_TYPE } from '@constants/navigation'
import { useCountryCode } from '@hooks/use_country_code'
import { useUserData } from '@hooks/use_user_data'

import { CLOSE_ICON_HEIGHT, CLOSE_ICON_WIDTH } from './constants'
import * as styles from './styles'

const HeaderNavigationDrawer = ({ isOpen, closeNavDrawer }) => {
  const { data, loading } = useUserData()
  const country = useCountryCode()

  const renderBackgroundOverlay = () => {
    if (!isOpen) return null

    return (
      <a
        css={[styles.overlay, isOpen && styles.overlayOpen]}
        onClick={closeNavDrawer}
        data-testid='overlay'
        role='button'
        href='#'
      />
    )
  }

  const renderCloseButton = () => (
    <button
      css={styles.closeButton}
      onClick={closeNavDrawer}
      data-testid='close-button'
    >
      <CloseIcon
        height={CLOSE_ICON_HEIGHT}
        width={CLOSE_ICON_WIDTH}
      />
    </button>
  )

  const renderLoginLinks = () => (
    <div css={styles.loginContainer}>
      <HeaderAuthButton variant={DRAWER_NAVBAR_TYPE} />
      <HeaderAuthButton
        type={SIGNUP_TYPE}
        variant={DRAWER_NAVBAR_TYPE}
      />
    </div>
  )

  const renderUserSection = () => {
    if (!data?.accountsViewer?.isLoggedIn) return renderLoginLinks()

    return (
      <div css={styles.userSectionContainer}>
        <HeaderProfileSection closeNavDrawer={closeNavDrawer} />
      </div>
    )
  }

  const renderCountryLink = () => (
    <div css={styles.countryLinkContainer}>
      <CountryLink
        country={country}
        position='header'
        displayName
      />
    </div>
  )

  if (loading) return null

  return (
    <Fragment>
      {renderBackgroundOverlay()}
      <nav css={[styles.drawer, isOpen && styles.drawerOpen]}>
        {renderCloseButton()}
        <div css={styles.logoContainer}>
          <HeaderLogo type={'light'} />
        </div>
        {renderCountryLink()}
        <div css={styles.discountsButtonContainer}>
          <HeaderDiscountsButton variant={DRAWER_NAVBAR_TYPE} />
        </div>
        <HeaderNavLinks
          variant='drawer'
          closeDrawer={closeNavDrawer}
        />
        {renderUserSection()}
      </nav>
    </Fragment>
  )
}

HeaderNavigationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeNavDrawer: PropTypes.func.isRequired
}

export default HeaderNavigationDrawer
