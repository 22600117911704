import PropTypes from 'prop-types'

import ChevronIcon from '@thebeansgroup/ui_icons/chevron_icon'

import Flag from '@components/flag'
import { getNextRoute } from '@helpers/routes'

import { FLAG_HEIGHT, FLAG_WIDTH, ICON_WIDTH } from './constants'
import { getCountryName, shouldRenderDisplayName } from './helpers'
import * as styles from './styles'

const CountryLink = ({ country, position, displayName }) => {
  const renderDisplayName = () => {
    const countryName = getCountryName(country)

    if (!shouldRenderDisplayName(displayName, countryName)) return null

    return <span css={styles.name}>{countryName}</span>
  }

  return (
    <a
      css={styles.link}
      href={getNextRoute('country.links', { country })}
      data-testid={`country-link-${position}`}
    >
      {renderDisplayName()}
      <Flag
        country={country}
        width={FLAG_WIDTH}
        height={FLAG_HEIGHT}
      />
      <div css={styles.chevronIcon}>
        <ChevronIcon
          rotateDegrees={90}
          width={ICON_WIDTH}
        />
      </div>
    </a>
  )
}

CountryLink.propTypes = {
  country: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['header', 'footer']).isRequired,
  displayName: PropTypes.bool
}

CountryLink.defaultProps = {
  displayName: false
}

export default CountryLink
