import PropTypes from 'prop-types'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'
import { getNextRoute } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'

import { getLogoPath } from './helpers'
import * as styles from './styles'

const HeaderLogo = ({ type }) => {
  const country = useCountryCode()

  return (
    <a
      css={styles.logoLink}
      href={getNextRoute('home', { country })}
      data-testid='nav-logo'
    >
      <SafeImage
        src={withAssetPrefix(getLogoPath(type))}
        alt='BeansiD Logo'
        width={172}
        height={36}
      />
    </a>
  )
}

HeaderLogo.propTypes = {
  country: PropTypes.string,
  type: PropTypes.oneOf(['dark', 'light'])
}

HeaderLogo.defaultProps = {
  country: 'uk',
  type: 'dark'
}

export default HeaderLogo
