/* eslint-disable @thebeansgroup/sb/safe-image */
import Image from 'next/image'
import PropTypes from 'prop-types'

const SafeImage = ({
  css,
  alt,
  src,
  height,
  width,
  style,
  fill,
  priority,
  testId,
  ...rest
}) => {
  if (!src) {
    return null
  }
  return (
    <Image
      css={css}
      alt={alt}
      src={src}
      height={height}
      width={width}
      style={style}
      fill={fill}
      unoptimized
      priority={priority}
      data-testid={testId}
      {...rest}
    />
  )
}

SafeImage.defaultProps = {
  css: '',
  src: '',
  height: undefined,
  width: undefined,
  style: null,
  fill: false,
  priority: false,
  testId: null
}

SafeImage.propTypes = {
  css: PropTypes.string,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  fill: PropTypes.bool,
  priority: PropTypes.bool,
  testId: PropTypes.string
}

export default SafeImage
