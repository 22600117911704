import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { FOR_BUSINESS_ROUTE } from '@constants/routes'

export const getLinkColour = (variant) => {
  if (variant === 'drawer') return Colours('huckleberry', '500')

  return Colours('white')
}

export const getNavLinks = (country) => [
  {
    label: 'How it works',
    href: `/${country}#how-it-works`
  },
  {
    label: 'Help',
    href: `/${country}#help`
  },
  {
    label: 'For Businesses',
    href: FOR_BUSINESS_ROUTE
  }
]
