import { gql } from '@apollo/client'

import { HEADER_NAVIGATION_DRAWER_VIEWER_FRAGMENT } from '@components/header_navigation_drawer/constants'
import { HEADER_TOP_USER_SECTION_VIEWER_FRAGMENT } from '@components/header_top_user_section/constants'

export const HEADER_VIEWER_FRAGMENT = gql`
  fragment HeaderViewer on AccountsViewer {
    id
    ...HeaderNavigationDrawerViewer
    ...HeaderTopUserSectionViewer
  }
  ${HEADER_NAVIGATION_DRAWER_VIEWER_FRAGMENT}
  ${HEADER_TOP_USER_SECTION_VIEWER_FRAGMENT}
`
