import { COLOUR_NAMES } from './constants'
import * as styles from './styles'

export const validateColour = (colour) => {
  if (COLOUR_NAMES.includes(colour)) return

  throw new Error(
    `Invalid button colour: ${colour}. Please choose one of ${COLOUR_NAMES.join(
      ', '
    )}.`
  )
}

export const getVariantCSS = (colour, variant) => {
  if (variant === 'outlined') return styles[variant]

  return styles[variant](colour)
}

export const getCSS = (css, colour, variant) => {
  validateColour(colour)
  const variantCSS = getVariantCSS(colour, variant)

  return [styles.colourStyles(colour), variantCSS, css]
}
